.form {
  .form-label {
    font-size: 13px;
    color: var(--label-color);
    font-weight: var(--label-weight);
    // font-weight: calc(var(--label-weight) * 100);
  }
}

.title-group {
    color: var(--legend-color);
    font-weight: var(--legend-weight);
  }