.stepper {
  position: relative;

  .step-item {
    margin-left: 2px;
    margin-right: 2px;

    &:first-child .step--content {
      border-top-left-radius: 50px !important;
      border-bottom-left-radius: 50px !important;
      margin-left: 0;
    }
    &:last-child .step--content {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      margin-right: 0;
    }

    .step--content {
      // background-color: #f0f0f0;
      background-color: var(--inactive-color);
      padding-top: 2px;
      padding-bottom: 2px;

      @include media-breakpoint-up(md) {
        height: 28px;
      }
    }

    &.done {
      // $main-color: #990000;

      .step--content {
        // background-color: $main-color;
        // border-color: $main-color;
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }

      .step-number {
        // color: $main-color;
        color: var(--primary-color);
      }
    }
    &.active {
      $main-color: #c80101;
      border-color: $main-color;

      .step-number {
        background-color: $main-color;
        color: #fff;
        z-index: 0;
      }
      .gauge-bar {
        right: 60%;
        // background-color: $main-color;
        background-color: var(--primary-color);
      }
    }
  }

  .step-number {
    color: #8f8f8f;
    background-color: #f0f0f0;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 8px;
    width: 17px;
    height: 17px;
    text-align: center;
    display: block;

    .bi {
      font-size: 15px;
      position: relative;
      top: -5px;
    }

    @include media-breakpoint-up(md) {
      font-size: 12px;
      width: 28px;
      height: 28px;
      position: relative;
      top: -2px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bi {
        position: initial;
        font-size: 22px;
      }
    }
  }

  .gauge-bar {
    right: 100%;
    top: 0;

    img {
      right: -22px;
      height: 26px;
      top: -4px;

      @include media-breakpoint-up(md) {
        right: -34px;
        height: 40px;
        top: -6px;
      }
    }
  }
}
