.btn:not(label) {
  box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.2) !important;
  // border-radius: 50px !important;
  border-radius: var(--button-border-radius) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  //border: none;
  border-color: var(--next-button-border-color);
  background-color: var(--btn-bg);
  color: var(--btn-color);
  // height: 36px;
  // padding: calc(var(--button-padding) * 1px);
  padding: var(--button-padding) !important;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--btn-hover-active-bg);
    // color: var(--btn-hover-active-color);
    color: var(--btn-color);
  }

  &.btn-primary {
    // background-color: var(--btn-primary-bg);
    background-color: var(--next-button-color);
    color: var(--btn-primary-color);

    &:hover,
    &:focus,
    &:active {
      // background-color: var(--btn-primary-hover-active-bg);
      // // color: var(--btn-primary-hover-active-color);
      // color: var(--btn-primary-color);
      background-color: inherit;
      color: inherit;
    }
  }

  &.btn-outline-primary {
    background-color: (white);
    border: 1px solid var(--btn-primary-bg);
    color: var(--btn-primary-bg);
  }

  // @include media-breakpoint-up(md) {
  //   height: 48px;
  // }
}

.btn-secondary {
  // color: $color-secondary !important;
  // background-color: #fff;
  background-color: var(--back-button-color) !important;
  border-color: var(--back-button-border-color) !important;
}

// body * {
//   background-color: var(--btn-primary-bg);
// }
