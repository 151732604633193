.btn-group {
  padding: 2px;
  background-color: #f5f9fb;
  border-radius: 13px;
  width: 100%;
}
.btn-group .btn {
  color: #222222;
  background-color: transparent;
  border: none;
}

.btn-group .btn-check:checked + .btn,
.btn-group .btn.active,
.btn-group .btn.show,
.btn-group .btn:first-child:active,
.btn-group :not(.btn-check) + .btn:active {
  background-color: #ffffff;
  color: #137e84;
  font-weight: 700;
  box-shadow: 0px 1px 6px 0px #44444433;
  border-radius: 9px !important;
}
.btn-check:not(:checked) + .btn:hover {
  background-color: #eaf0f3 !important;
  color: #222222 !important;
}
