@import "~/node_modules/react-phone-number-input/style.css";
@import "~/node_modules/bootstrap-icons/font/bootstrap-icons";

// $theme-colors: (
//   "primary": #ec0000
// );

$bs-border-color: #8f8f8f;
// $btn-border-radius: 50px;
$color-secondary: #137e84;

@import "variables";

@import "~/node_modules/bootstrap/scss/bootstrap";
// @import "~/node_modules/bootstrap/scss/bootstrap-utilities";
// @import "~/node_modules/bootstrap/scss/"
// @import "~/node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "utilities";
@import "phone-input-country";
@import "btn";
@import "btn-group";
@import "stepper";
@import "form";
@import "form-control-group";

:root,
[data-bs-theme="light"] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ec0000;
  --bs-btn-hover-bg: #bd0000;
  --bs-btn-active-bg: #bd0000;
  --bs-btn-disabled-bg: #c67373;
  --bs-btn-border-width: 0;

  --bs-border-color: #8f8f8f;

  --PhoneInputCountrySelectArrow-width: 8px;
  --PhoneInputCountrySelectArrow-borderWidth: 2px;
  --PhoneInputCountrySelectArrow-color--focus: #ec0000;
  --PhoneInputCountryFlag-borderWidth: 0;
}
