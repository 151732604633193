.btn-group {
    padding: 2px;
    background-color: #F5F9FB;
    border-radius: 13px;
}

.btn-group .btn {
    color: #222222;
    background-color: transparent;
    border: none;
}

.btn-group .btn-check:checked+.btn,
.btn-group .btn.active,
.btn-group .btn.show,
.btn-group .btn:first-child:active,
.btn-group :not(.btn-check)+.btn:active {
    background-color: #FFFFFF;
    color: #137E84;
    font-weight: 700;
    box-shadow: 0px 1px 6px 0px #44444433;
    border-radius: 9px !important;
}

.btn-check:not(:checked)+.btn:hover {
    background-color: #eaf0f3 !important;
    color: #222222 !important;
}

/* .btn-group:not(:last-child)>.btn , 
.btn.dropdown-toggle-split:first-child, 
.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
} */