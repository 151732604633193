.PhoneInputCountry {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 6px 12px;
  color: #137e84;
}
.PhoneInputInput {
  border-radius: 5px;
  border: 1px solid grey;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.PhoneInputCountry:focus-within,
.PhoneInputInput:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
