.form-control-group {
  // border: 1px dashed #23779a;

  @include media-breakpoint-up(md) {
    margin-top: 10px;
  }

  // .dashed-border {
  //   border-color: #23779a;

  //   @include media-breakpoint-up(md) {
  //     margin-left: -14px;
  //     margin-right: -14px;
  //   }
  // }

  // .description {
  //   color: #23779a;
  // }
}

.is-invalid {
  .PhoneInputInput {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    // background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
